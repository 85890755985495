<template>
  <div class="all-gift">
    <div class="list-item" v-for="(item,index) in allGifts" :key="index">
      <img :src="item.icon" alt />
      <div class="description">
        <p>《{{item.gameName}}》 {{item.giftName}}</p>
        <p>领取资格：{{item.giftCondition}}</p>
        <p>{{item.giftDetail}}</p>
      </div>
      <button
        type="button"
        @click="look(index)"
        v-if="GlobalObject.User && item.userId == GlobalObject.User.userId "
      >查看</button>
      <button type="button" @click="receive(index)" v-else>领取</button>
    </div>
    <div class="foot">
      <a href="javascript:void(0)" @click="viewMore">查看更多&gt;&gt;</a>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Const from "../../commons/const.js";
import util from "../../commons/util.js";

export default {
  created() {
    this.viewMore();
  },
  data() {
    return {
      allGifts: [],
      pageNum: 1,
      pageRow: 5,
      GlobalObject
    };
  },
  methods: {
    //查看礼包
    look(index) {
      if (GlobalObject.User) {
        util.showGiftModal(this.allGifts[index].code);
      } else {
        util.showPromptModal("请登录后再查看", () => {
          this.$router.push("login");
        });
      }
    },
    //领取礼包
    receive(index) {
      const currGift = this.allGifts[index];
      if (currGift.giftCount < 1) {
        util.showPromptModal("当前礼包已经领取完了");
        return;
      }
      if (GlobalObject.User) {
        axios
          .post("/gift/getGift", {
            id: currGift.id
          })
          .then(response => {
            if (response.returnCode == Const.RequestSuccess) {
              util.showGiftModal(response.returnData[0].code);
            } else {
              util.showPromptModal(response.returnMsg);
            }
          });
      } else {
        util.showPromptModal("请先登录", () => {
          this.$router.push("login");
        });
      }
    },
    viewMore() {
      axios
        .get("/gift/listGift", {
          params: {
            userId: GlobalObject.User ? GlobalObject.User.userId : undefined,
            pageNum: this.pageNum,
            pageRow: this.pageRow
          }
        })
        .then(response => {
          const list = response.returnData.list;
          if (list.length > 0) {
            this.pageNum += 1;
            this.allGifts.push(...list);
          } else {
            util.toast("没有更多了");
          }
        });
    }
  }
};
</script>

<style>
</style>
