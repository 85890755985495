<template>
  <div>
    <mly-header></mly-header>
    <top></top>

    <all-gift v-show="showAllGift"></all-gift>
    <my-gift v-show="!showAllGift"></my-gift>
    <mly-placeholder></mly-placeholder>
    <mly-toolbar></mly-toolbar>
  </div>
</template>
<script>
import top from "./top.vue";
import allGift from "./all-gift.vue";
import myGift from "./my-gift.vue";

export default {
  components: {
    top,
    allGift,
    myGift
  },
  created() {
    EventBus.$on("btnSwitch", () => {
      this.showAllGift = !this.showAllGift;
    });
  },
  data() {
    return {
      showAllGift: true
    };
  }
};
</script>

