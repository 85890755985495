<template>
  <section :class="$style.wrap">
    <div :class="$style.btnGroup">
      <button
        type="button"
        class="btn"
        :class="allGiftsSelect?'active':''"
        @click="allGiftClickHandle"
      >
        <img :src="allGiftsSelect?btnGifts2:btnGifts1" alt>全部礼包
      </button>
      <button
        type="button"
        class="btn"
        :class="!allGiftsSelect?'active':''"
        @click="myGiftClickHandle"
      >
        <img :src="allGiftsSelect?btnGifts1:btnGifts2" alt>我的礼包
      </button>
    </div>
    <div :class="$style.banner">
      <img src="@/assets/banner_all_gifts.jpg" class="img-responsive" alt>
    </div>
  </section>
</template>
<script>
import util from '../../commons/util.js'

import btnGifts1 from "@/assets/btn_gifts_1.png";
import btnGifts2 from "@/assets/btn_gifts_2.png";

export default {
  data() {
    return {
      btnGifts1,
      btnGifts2,
      allGiftsSelect: true
    };
  },
  methods: {
    allGiftClickHandle() {
      if (this.allGiftsSelect) {
        return;
      }
      this.allGiftsSelect = true;
      EventBus.$emit("btnSwitch");
    },
    myGiftClickHandle() {
      if (!this.allGiftsSelect) {
        return;
      }
      if (!GlobalObject.User) {
        util.showPromptModal('请登录后再查看',()=>{
          this.$router.push("login");
        })
        return;
      }
      this.allGiftsSelect = false;
      EventBus.$emit("btnSwitch");
    }
  }
};
</script>
<style module>
.wrap {
  padding: 0.32rem 0.26rem;
  border-bottom: 0.2rem solid #f4f4f4;
}

.btn-group {
  width: 100%;
}

.btn-group button {
  width: 50%;
  color: #20a2dc;
  background-color: white;
  border-color: #20a2dc;
  font-size: 0.31rem;
  padding: 0.18rem;
}

.btn-group button:nth-child(1) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group button:nth-child(2) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group button img {
  width: 0.31rem;
  height: 0.31rem;
  position: relative;
  right: 0.1rem;
  bottom: 0.02rem;
}

.wrap :global(.active) {
  background-color: #20a2dc;
  box-shadow: none;
  color: white;
}

.banner img {
  width: 100%;
  height: 100%;
}
</style>
