<template>
  <div class="my-gift">
    <div class="list-item" v-for="(item,index) in myGifts" :key="index">
      <img :src="item.icon" alt>
      <div class="description">
        <p>《{{item.gameName}}》 {{item.giftName}}</p>
        <p>序列码：{{item.code}}</p>
        <p>{{item.giftDetail}}</p>
      </div>
      <button
        type="button"
        v-clipboard:copy="item.code"
        v-clipboard:success="onCopy"
        v-clipboard:error="onError"
      >复制</button>
    </div>
    <div class="foot">
      <a href="javascript:void(0)" @click="viewMore">查看更多&gt;&gt;</a>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import axios from 'axios';
import vueClipboard2 from "vue-clipboard2";
Vue.use(vueClipboard2);

import util from "../../commons/util.js";

export default {
  data() {
    return {
      myGifts: [],
      pageNum: 1,
      pageRow: 5
    };
  },
  methods: {
    onCopy: function() {
      //复制成功
      util.toast("复制成功");
    },
    onError: function() {
      //复制失败
      util.toast("复制失败，当前浏览器不支持自动复制");
    },
    viewMore() {
      axios
        .get("/gift/getMyGift", {
          params: {
            userId: GlobalObject.User ? GlobalObject.User.userId : undefined,
            pageNum: this.pageNum,
            pageRow: this.pageRow
          }
        })
        .then(response => {
          const list = response.returnData.list;
          if (list.length > 0) {
            this.pageNum += 1;
            this.myGifts.push(...list);
          } else {
            util.toast("没有更多了");
          }
        });
    }
  }
};
</script>

<style>
</style>
